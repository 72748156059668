import React, { useRef } from 'react';

import {
    grid,
    wrapper,
    sectionBox,
    title as titleClass,
    subtitleText,
} from './sign-up.module.scss';
import { TSectionSignInUp } from '../../templates/sign-in-up';
import useTranslations from '../../hooks/use-translations';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import Section from '../hoc/section';
import Title from '../atoms/title';
import ClientDataForm from '../organisms/client-data-form';

export interface ISignUpProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: TSectionSignInUp;
}

export default function SignUpClient({ className, section, TitleTag = 'h2' }: ISignUpProps) {
    const t = useTranslations('SignUp');
    const sectionRef = useRef<HTMLElement>(null);
    const SubtitleTag = getLessImportantHeading(TitleTag) || 'p';

    return (
        <Section
            className={`${className} ${sectionBox}`}
            classes={{ container: grid }}
            style={section.style}
            ref={sectionRef}
            roundedSection={true}
        >
            <div className={wrapper}>
                <>
                    <Title Tag={TitleTag} className={titleClass}>
                        {t.title}
                    </Title>
                    <SubtitleTag className={subtitleText}>{t.subtitle}</SubtitleTag>
                    <ClientDataForm type="register" />
                </>
            </div>
        </Section>
    );
}
